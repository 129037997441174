import React, {useState} from "react";
import Collapse from "react-bootstrap/Collapse";
import * as styles from "./collapse-items.module.scss"
import CollapseMenuItem from "./menu-item";
import VerticalSpacingVerySmallRow from "../vertical-spacing-very-small/vertical-spacing-very-small";
import {Col, Container, Row} from "react-bootstrap";

export default function CollapseItems(props) {
    const [open, setOpen] = useState([]);

    return <Container fluid>
        <Row noGutters>
            <Col md={{span: 10, offset: 1}}>
            <VerticalSpacingVerySmallRow />
            <em className={styles.hint}>kliknij lub dotknij nazwę kategorii aby wyświetlić listę dań</em>
                {props.itemList.map((item, index) =>
                    <div key={index} className={styles.listItems}>
                        <VerticalSpacingVerySmallRow />
                        <button
                            className={styles.collapseButton}
                            onClick={() => {
                                const idx = open.indexOf(index)
                                idx !== -1 ? setOpen(open.filter(e => e !== index)) : setOpen([...open, index])
                            }}
                            aria-controls="menuItems"
                            aria-expanded={open}
                        >
                            {item.name}
                            <div className={open.indexOf(index) !== -1 ? styles.up : styles.down}></div>
                        </button>

                        <Collapse in={open.indexOf(index) !== -1}>
                            <div id="menuItems" className={styles.menuItems}>
                                {item.menuEntries?.sort((a, b) => a.position - b.position).map((dish, idx) =>
                                    <div key={idx}>
                                        <CollapseMenuItem
                                            dishName={dish.name}
                                            dishDescription={dish.description}
                                            isVegan={dish.isVegan}
                                            isGlutenFree={dish.isGlutenFree}
                                            isGlutenFreeAvailable={dish.isGlutenFreeAvailable}
                                            isDairyFree={dish.isDairyFree}
                                            dishPrices={dish.prices}
                                        />
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </div>
                )}
            </Col>
        </Row>
    </Container>
}