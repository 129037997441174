import React from "react";
import VeganIconSVG from "../../svg/icons/vegan.svg";
import GlutenFreeIconSVG from "../../svg/icons/gluten-free.svg";
import DairyFreeIconSVG from "../../svg/icons/no-dairy.svg";
import * as styles from "./menu-item.module.scss";

export default function CollapseMenuItem(props) {
    const price = Intl.NumberFormat('pl-PL', {style: 'currency', currency: 'PLN'});

    return <div className={styles.collapseMenuItem}>
        <div className={styles.dishNameContainer}>{props.dishName && <div>{props.dishName}</div>}
        <DishAttributes
            isVegan={props.isVegan}
            isGlutenFree={props.isGlutenFree}
            isGlutenFreeAvailable={props.isGlutenFreeAvailable}
            isDairyFree={props.isDairyFree} /></div>
        {props.dishDescription && <em>{props.dishDescription}</em>}

        <div className={styles.divider}></div>

        <div className={styles.prices}>
            {props.dishPrices.length > 0
            ? props.dishPrices.map((e, i) =>
                <div key={i}>
                    <span>{price.format(e.value)}</span>
                    {e.description && <em>{e.description}</em>}
                </div>
            )
            : <div>
                <span className={styles.noPrice}>zapytaj kelnera</span>
            </div>}
        </div>
    </div>
}

function DishAttributes(props) {
    return <>
        {props.isVegan && <VeganIconSVG className={`${styles.icon} ${styles.attribute}`} />}
        {props.isGlutenFree && <GlutenFreeIconSVG className={`${styles.icon} ${styles.attribute}`} />}
        {props.isGlutenFreeAvailable && <em className={`${styles.attribute} ${styles.textAttribute}`}>(zapytaj o gluten free)</em>}
        {props.isDairyFree && <DairyFreeIconSVG className={`${styles.icon} ${styles.attribute}`} />}    
    </>;
}